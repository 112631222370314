<template>
  <div>
    <CRow>
      <CCol xs="12" md="12" class="align-self-center">
        <CCard>
          <CCardHeader>
            <center><strong>Su Orden</strong></center>
          </CCardHeader>
          <CCardBody>
            <CRow>
                <CCol xs="12" md="6" class="align-self-center">
                    <p><b>Identificación:</b> {{ cart.cart.identification }}</p>
                    <p><b>Nombre:</b> {{ cart.cart.name }}</p>
                    <p><b>Correo:</b> {{ cart.cart.email }}</p>
                    <p><b>Teléfono:</b> {{ cart.cart.phone }}</p>
                    <p><b>Monto total:</b> {{ formatPrice(cart.cart.total) }}</p>
                </CCol>
                <CCol xs="12" md="6" class="align-self-center" v-if="cart.cart.paymentCode!=null">
                    <CCardBody>
                        <CCardTitle><center>Enlace de pago</center></CCardTitle>
                        <br />
                        <center>Comparte el código qr con el cliente para completar el pago</center>
                        <center>
                        <qrcode-vue :value="cart.cart.paymentCode" level="H" />
                        </center>
                        <br/>
                        <center><CButton
                            class="js-payment-checkout"
                            color="info"
                            @click="copy"
                        >
                            <font-awesome-icon icon="copy" /> Copiar enlace
                        </CButton></center>
                        <br />
                    </CCardBody>
                </CCol>
            </CRow>
            <br>
            <p><b>Productos:</b></p>
            <CDataTable
                hover
                striped
                :items="cart.items"
                :fields="itemFields"
            >
                <template #total="data">
                    <td>
                        {{ formatPrice(data.item.total) }}
                    </td>
                </template>
                <template #Price="data">
                    <td>
                        {{ formatPrice(data.item.Price) }}
                    </td>
                </template>
            </CDataTable>
            <br>
            <p><b>Transacciones:</b></p>
            <CDataTable
                hover
                striped
                :items="cart.transactions"
                :fields="transactionFields"
            >
                <template #status="data">
                    <td>
                         <CBadge :color="getBadge(data.item.status)">
                            {{data.item.status}}
                        </CBadge>
                    </td>
                </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Resource from '@/api/resource';
import QrcodeVue from "qrcode.vue";

const cartResource = new Resource('cart');

export default {
    name: 'Cart',
    components: {
        QrcodeVue,
    },
    data: function () {
        return {
            form: {},
            products: [],
            lessons: [],
            show: true,
            isCollapsed: true,
            cart: {},
            itemFields: [
                { key: 'ItemCode', label: 'Codigo', _classes: 'font-weight-bold'},
                { key: 'ItemName', label: 'Name' },
                { key: 'quantity', label: 'Cantidad'},
                { key: 'total', label: 'Total' },
            ],
            transactionFields: [
                { key: 'authorization_code', label: 'Codigo', _classes: 'font-weight-bold'},
                { key: 'carrier', label: 'Carier' },
                { key: 'id_paymentez', label: 'Transaction ID'},
                { key: 'status', label: 'Estado'},
                { key: 'amount', label: 'Monto' },
            ],
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    mounted() {
        this.loadCart();
    },
    methods: {
        loadCart(){
            cartResource.get(this.id).then(response => {
                this.cart = response.data;
            })
            .catch(error => {
                //console.log(error);
            });
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        toKebabCase (str) {
            return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        getBadge (status) {
            switch (status) {
                case 'success': return 'success'
                case 'error': return 'danger'
                default: 'primary'
            }
        },
        async copy() {
        try {
            await navigator.clipboard.writeText(process.env.VUE_APP_FRONTEND_URL+"/#/pages/Payment/" + this.cart.cart.paymentCode);
            //alert('Copied');
        } catch ($e) {
            //alert('Cannot copy');
        }
        },
    }
}
</script>

<style scoped>
.prices{
    right: 0;
    position: absolute;
    margin-right: 10px;
}
</style>